import React, { useContext, useEffect, Component } from 'react'
import WebSocketClient from './WebSocketClient';
import track from '../lib/track'
import {
    H
} from '../lib/strings'
import {
    GlobalDispatchContext,
    GlobalStateContext,
} from "../context/GlobalContextProvider"
import Cookies from 'js-cookie'
import {getParameterByName} from '../lib/url'

const isSSR = typeof window === 'undefined';
if (!isSSR) {
  if (!Cookies.get('vid')) {
    track({
      ename: 'first_visit'
    }, true);
  } else {
    track({
      ename: 'opened'
    }, true);
  }
}

let socket=null, RealtimeProvider = ({ state, dispatch }) => {return (<></>)};
if (process.env.GATSBY_API_WS) {
    const mySubs = new Set();
    mySubs.add({
        hash: 'f98ea2bf26117c0f8d924c7106171f41df7594816c6b3966cb2a942c971cc7ed', msg: {
            slug: '/api/v1/sub/system',
        }
    });

    const mySubsHashes = new Set();
    mySubsHashes.add('f98ea2bf26117c0f8d924c7106171f41df7594816c6b3966cb2a942c971cc7ed')

    socket = new WebSocketClient(`${process.env.GATSBY_API_WS}/${Cookies.get(process.env.GATSBY_CLIENT_OWNER_COOKIE)}/${Cookies.get(process.env.GATSBY_CLIENT_SESS_COOKIE)}`);
    
    let room = "";
    if (typeof window !== 'undefined') {
        room = getParameterByName('room');
        room = (!!room) ? "-" + room : "";
    }

    socket.onopen = () => {
        socket.send(JSON.stringify({ slug: '/ping' }))
        socket.send(JSON.stringify({
            slug: '/api/v2/subscribe/public-ephemeral' + room
        }));
        if (Cookies.get('vid')) {
            socket.send(JSON.stringify({
                slug: '/api/v2/subscribe/public-ephemeral' + room + "-v-" + Cookies.get('vid')
            }));        
        }
        for (let item of mySubs) {
            socket.send(JSON.stringify(item.msg));
        }
    }

    RealtimeProvider = ({ state, dispatch }) => {
        //const dispatch = useContext(GlobalDispatchContext)
        //const gstate = useContext(GlobalStateContext)

        useEffect(() => {
            socket.onmessage = ({ data }) => {
                GlobalStateContext.trigger('WS_MSG', data)
                dispatch({ type: "WS_MSG", data })
            }
            GlobalStateContext.on('WS_SUB', async (data) => {
                console.log('WS_SUB', data)
                if (data && data.slug && /\/subscribe\//ig.test(data.slug)) {
                    const hash = await H(data)
                    if (!mySubsHashes.has(hash)) {
                        mySubsHashes.add(hash);
                        mySubs.add({ hash: hash, msg: data })
                        socket.send(JSON.stringify(data));
                    }
                }
            });
        }, []);

        return (
            <></>
        )
    }
}




export {
    RealtimeProvider,
    socket
}
