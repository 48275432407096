import React, { useEffect, useContext } from "react"
import mitty from "mitty";
import { RealtimeProvider } from "../realtime"
import Cookies from 'js-cookie'
import { v1 as uuidv1 } from 'uuid';
import moment from 'moment';
import { socket } from '../realtime'
import { sounds, fonts } from '../../package.json';
import { getParameterByName } from '../lib/url'

export const GlobalStateContext = React.createContext()
export const GlobalDispatchContext = React.createContext()

function randomColor(brightness) {
  function randomChannel(brightness) {
    var r = 255 - brightness;
    var n = 0 | ((Math.random() * r) + brightness);
    var s = n.toString(16);
    return (s.length == 1) ? '0' + s : s;
  }
  return '#' + randomChannel(brightness) + randomChannel(brightness) + randomChannel(brightness);
}


const initialState = {
  deafaults: {
    VIDEO_WIDTH: 64,
    VIDEO_HEIGHT: 48
  },
  isMobile: false,
  theme: "light",
  item: {},
  ajwt: Cookies.get(process.env.GATSBY_CLIENT_AUTH_COOKIE),
  sid: Cookies.get(process.env.GATSBY_CLIENT_SESS_COOKIE),
  vid: Cookies.get(process.env.GATSBY_CLIENT_OWNER_COOKIE),
  items: [],
  ids: {}, //people in the chat
  streams: {}, //text streams
  peers: {}, //webrtc peers
  ttl: 9000,
  room: '',
  shareMedia: false,
  media: null, //my media stream object of our video
  mediaConstraints: {
    video: {
      mandatory: {
        maxWidth: 64,
        maxHeight: 48
      },
      optional: [{ maxFrameRate: 10 }]
    },
    audio: true
  }
}
initialState.streams[initialState.vid] = [{ msg: "", exp: Date.now() + initialState.ttl }];
initialState.ids[initialState.vid] = { pos: { x: 100, y: 100, w: 100, h: 500 }, color: randomColor(100), sound: '/sounds/animals/' + sounds[Math.floor(Math.random() * sounds.length)], font: '/fonts/chats/' + fonts[Math.floor(Math.random() * fonts.length)] }

const isSSR = typeof window === 'undefined';
if (!isSSR) {
  initialState.isMobile = navigator && (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4)));
  initialState.room = getParameterByName('room');
  initialState.room = (!!initialState.room) ? "-" + initialState.room : "";
}



function reducer(state, action) {
  switch (action.type) {
    case "TOGGLE_THEME": {
      return {
        ...state,
        theme: state.theme === "light" ? "dark" : "light",
      }
    }
    case "RESET_PEER_DATA": {
      if (socket) {
        socket.send(JSON.stringify({
          slug: '/api/v2/publish/public-ephemeral' + state.room,
          data: { from: state.vid, t: 'wr' } //wr = webrtc reset
        }))
      }
      for (let key in state.peers) {
        if (state.peers[key].pc) {
          state.peers[key].pc.close();
        }
      }
      return {
        ...state,
        peers: {}
      };
    }
    case "UPDATE_PEER_MEDIA": {
      state.peers[action.key].streams = action.streams
      return state;
    }
    case "UPDATE_MEDIA": {
      return {
        ...state,
        media: action.data
      }
    }
    case "TOGGLE_SHARE_MEDIA": {
      if (state.shareMedia) {
        GlobalStateContext.trigger('stop-video', { key: state.vid })
        if (state.media) {
          const tracks = state.media.getTracks();
          tracks.forEach(function (track) {
            track.stop();
          });
          state.media = null;
        }
        for (let key in state.peers) {
          if (state.peers[key].pc) {
            state.peers[key].pc.close();
          }
        }
      }
      return {
        ...state,
        shareMedia: !state.shareMedia,
      }
    }
    case "ITEM_UPDATED": {
      return {
        ...state,
        item: action.data || state.item || {},
      }
    }
    case "TIMER": {
      return {
        ...state,
        item: action.data || state.item || {},
      }
    }
    case "CLEAN_STREAMS": {
      const now = Date.now()
      let newStreams = {};
      for (let name in state.streams) {
        newStreams[name] = [];
        for (let stream in state.streams[name]) {
          if (state.streams[name][stream].exp > now) {
            newStreams[name].push(state.streams[name][stream])
          }
        }
      }
      //TODO: Could prune empty arrays, refresh works for now
      return {
        ...state,
        streams: newStreams
      }
    }
    case "UPDATE_ME": {
      state.ids[state.vid] = { ...state.ids[state.vid], ...action.data };
      if (socket) {
        socket.send(JSON.stringify({
          slug: '/api/v2/publish/public-ephemeral' + state.room,
          data: { vid: state.vid, ...state.ids[state.vid], t: 'id' } //types: k=keystroke, id=identity (position, color, etc)            
        }))
      }
      return {
        ...state
      }
    }
    case "SEND_KEY": {
      if (socket) {
        if (action.data.keyCode == 8 || action.data.keyCode == 46) {
          socket.send(JSON.stringify({
            slug: '/api/v2/publish/public-ephemeral' + state.room,
            data: { vid: state.vid, keyCode: action.data.keyCode, t: 'k' }
          }))
        } else if (action.data.key) {
          socket.send(JSON.stringify({
            slug: '/api/v2/publish/public-ephemeral' + state.room,
            data: { vid: state.vid, key: action.data.key, t: 'k' }
          }))
        }
      }
      return state;
    }
    case "BROADCAST": {
      if (socket && state.ids[state.vid]) {
        socket.send(JSON.stringify({
          slug: '/api/v2/publish/public-ephemeral' + state.room,
          data: { vid: state.vid, ...state.ids[state.vid], t: 'id' },
        }))
      }
      if (state.shareMedia && socket && state.media) {

        const configuration = { iceServers: [{ urls: 'stun:stun.l.google.com:19302' }, { "urls": "turn:hurdygurdy.sfpl.io:5349", "username": "guest", "credential": "somepassword" }] };
        for (let key in state.ids) {
          if (key == state.vid)
            continue;
          if (state.peers[key] && state.peers[key].streams) {
            if (state.peers[key] && state.peers[key].pc) {
              if (state.peers[key].pc.connectionState === "connected" || state.peers[key].pc.connectionState === "connecting" || state.peers[key].pc.signalingState === "stable")
                continue;
              else {
                console.log("Attempting stop of pc while in state:", state.peers[key].pc.connectionState, state.peers[key].pc.signalingState)
                state.peers[key].pc.close()
              }
            }
          }
          const pc = new RTCPeerConnection(configuration);
          state.peers[key] = { ...state.peers[key], pc }
          pc.onicecandidate = ({ candidate }) => {
            socket.send(JSON.stringify({
              slug: '/api/v2/publish/public-ephemeral' + state.room + "-v-" + key,
              data: { from: state.vid, to: key, candidate, t: 'wc' } //wc = webrtc candidate
            }))
          }
          pc.onnegotiationneeded = async () => {
            try {
              const offer = await pc.createOffer();
              console.log('OFFERING')
              await pc.setLocalDescription(offer);
              console.log('OFFERING.')
              socket.send(JSON.stringify({
                slug: '/api/v2/publish/public-ephemeral' + state.room + "-v-" + key,
                data: { from: state.vid, to: key, offer, desc: pc.localDescription, t: 'wo' } //wx = webrtc offer
              }))
            } catch (err) {
              pc.close();
              console.error("Offering: ", err, "state", pc.connectionState, pc.signalingState);
            }
          };
          pc.ontrack = (event) => {
            if (state.peers[key] && state.peers[key].streams) return;
            console.log('::Adding remote video', key)
            GlobalStateContext.trigger('reconnect-video', { key, streams: event.streams })
          };
          pc.onconnectionstatechange = ev => {
            switch(pc.connectionState) {
              case "new":
              case "checking":            
                break;
              case "connected":
                break;
              case "disconnected":
                pc.close()
                break;
              case "closed":
                pc.close()
                break;
              case "failed":
                pc.close()
                break;
              default:
                break;
            }
          }

          try {
            // get local stream, show it in self-view and add it to be sent              
            state.media.getTracks().forEach((track) => pc.addTrack(track, state.media));
            //selfView.srcObject = stream;
            // let dataChannel = pc.createDataChannel("data", {
            //   negotiated: true,
            //   id: 0
            // });
            // dataChannel.onopen = function(event) {
            //   dataChannel.send('Message from' + state.vid);
            // }
            // dataChannel.onmessage = function(event) {
            //   console.log(event.data, ' my vid ', state.vid);
            // }              
          } catch (err) {
            console.error(err);
          }
        }


      }
      return state;
    }
    case "WS_MSG": {
      const packet = JSON.parse(action.data);

      let message = null

      switch (true) {
        case /^\/ping/ig.test(packet.slug):
          console.log('PingPong', packet)
          break;
        case /^\/thread\/public-ephemeral/ig.test(packet.slug):
          switch (packet.data.t) {
            case 'k':
              //const key = String.fromCharCode((96 <= packet.data.key && packet.data.key <= 105) ? packet.data.key-48 : packet.data.key)
              const key = packet.data.key;
              if (packet.data.vid) {
                if (!state.ids[packet.data.vid]) {
                  state.ids[packet.data.vid] = { color: '#FFFFFF', pos: { x: 25, y: 25, w: 100, h: 100 }, sound: '/sounds/animals/kookaburra.mp3', prev: 0, last: Date.now() }
                } else {
                  state.ids[packet.data.vid].prev = state.ids[packet.data.vid].last || 0;
                  state.ids[packet.data.vid].last = Date.now();
                }
                if (state.ids[packet.data.vid].last - state.ids[packet.data.vid].prev > 60000) {
                  GlobalStateContext.trigger('play', state.ids[packet.data.vid].sound)
                }
                if (!state.streams[packet.data.vid]) {
                  state.streams[packet.data.vid] = [{ msg: key || "", exp: Date.now() + initialState.ttl }];
                } else {
                  if (key === " " || key === "\t" || key === "\n" || key === "\r\n" || key === "\r") {
                    if (state.streams[packet.data.vid] && state.streams[packet.data.vid].length > 0 && state.streams[packet.data.vid][state.streams[packet.data.vid].length - 1].msg == "") {
                      return state;
                    }
                    state.streams[packet.data.vid].push({ msg: "", exp: Date.now() + initialState.ttl })
                  } else if (state.streams[packet.data.vid] && (packet.data.keyCode == 8 || packet.data.keyCode == 46)) {
                    if (state.streams[packet.data.vid].length > 0 && state.streams[packet.data.vid][state.streams[packet.data.vid].length - 1].msg.length > 0) {
                      state.streams[packet.data.vid][state.streams[packet.data.vid].length - 1].msg = state.streams[packet.data.vid][state.streams[packet.data.vid].length - 1].msg.slice(0, state.streams[packet.data.vid][state.streams[packet.data.vid].length - 1].msg.length - 1);
                      state.streams[packet.data.vid][state.streams[packet.data.vid].length - 1].exp = Date.now() + initialState.ttl;
                    } else {
                      //Start deleting in the previous array item if it exists
                      if (state.streams[packet.data.vid].length > 1) {
                        state.streams[packet.data.vid].splice(state.streams[packet.data.vid].length - 1, 1)
                        if (state.streams[packet.data.vid][state.streams[packet.data.vid].length - 1].msg.length > 0) {
                          state.streams[packet.data.vid][state.streams[packet.data.vid].length - 1].msg = state.streams[packet.data.vid][state.streams[packet.data.vid].length - 1].msg.slice(0, state.streams[packet.data.vid][state.streams[packet.data.vid].length - 1].msg.length - 1);
                          state.streams[packet.data.vid][state.streams[packet.data.vid].length - 1].exp = Date.now() + initialState.ttl;
                        }
                      }
                    }
                  } else {
                    if (Array.isArray(state.streams[packet.data.vid])) {
                      if (state.streams[packet.data.vid].length > 0) {
                        state.streams[packet.data.vid][state.streams[packet.data.vid].length - 1].msg = (state.streams[packet.data.vid][state.streams[packet.data.vid].length - 1].msg || "") + key;
                        state.streams[packet.data.vid][state.streams[packet.data.vid].length - 1].exp = Date.now() + initialState.ttl;
                      } else {
                        state.streams[packet.data.vid].push({ msg: key || "", exp: Date.now() + initialState.ttl })
                      }
                    }
                  }
                }
              }
              return { ...state };
            case 'id':
              if (packet.data.vid) {
                state.ids[packet.data.vid] = { ...state.ids[packet.data.vid], ...packet.data };
                return {
                  ...state
                }
              }
            //webrtc candidate
            case 'wc':
              if (!packet.data.from || !packet.data.candidate)
                return state;
              if (!state.peers[packet.data.from] || !state.peers[packet.data.from].candidates) {
                state.peers[packet.data.from] = { ...state.peers[packet.data.from], candidates: [packet.data.candidate] }
              } else {
                state.peers[packet.data.from].candidates.push(packet.data.candidate)
                if (state.peers[packet.data.from].pc && packet.data.candidate && packet.data.candidate.length > 0) {
                  state.peers[packet.data.from].pc.addIceCandidate(packet.data.candidate);
                }
              }
              return state;
            //webrtc offer
            case 'wo':
              if (!packet.data.from || !packet.data.offer || !packet.data.desc || packet.data.desc.type !== 'offer') {
                console.warn('Unsupported webrtc offer.', packet.data);
                return state;
              }
              if (!state.peers[packet.data.from] || !state.peers[packet.data.from].offer) {
                state.peers[packet.data.from] = { ...state.peers[packet.data.from], offer: packet.data.offer, rdesc: packet.data.desc }
              } else {
                state.peers[packet.data.from].offer = packet.data.offer;
                state.peers[packet.data.from].rdesc = packet.data.desc;
                if (state.peers[packet.data.from].pc && state.media) {

                  let reply = (async (pc) => {
                    try {
                      if (pc.connectionState == 'closed')
                        return;
                      console.log(packet.data)
                      console.log('OFFERED', pc.connectionState, pc.signalingState, packet.data, pc)
                      await pc.setRemoteDescription(packet.data.desc);
                      console.log('ANSWERING')
                      //state.media.getTracks().forEach((track) => pc.addTrack(track, state.media));
                      await pc.setLocalDescription(await pc.createAnswer());
                      console.log('ANSWERING.')
                      socket.send(JSON.stringify({
                        slug: '/api/v2/publish/public-ephemeral' + state.room + "-v-" + packet.data.from,
                        data: { from: state.vid, to: packet.data.from, desc: pc.localDescription, t: 'wa' } //wx = webrtc answer
                      }))
                    } catch (ex) {
                      console.warn("Error answering:", ex)
                      state.peers[packet.data.from].pc.close();
                      delete state.peers[packet.data.from]
                    }
                  });
                  reply(state.peers[packet.data.from].pc)

                }
              }
              return state;
            //webrtc answer
            case 'wa':
              if (!packet.data.from || !packet.data.desc || packet.data.desc.type !== 'answer') {
                console.warn('Unsupported webrtc answer.');
                return state;
              }
              if (!state.peers[packet.data.from] || !state.peers[packet.data.from].rdesc) {
                state.peers[packet.data.from] = { ...state.peers[packet.data.from], rdesc: packet.data.desc }
              } else {
                state.peers[packet.data.from].rdesc = packet.data.desc;
                if (state.peers[packet.data.from].pc) {
                  try {
                    console.log('ANSWERED', state.peers)
                    state.peers[packet.data.from].pc.setRemoteDescription(packet.data.desc);
                    console.log('ANSWERED.')
                  } catch (ex) {
                    state.peers[packet.data.from].pc.close()
                    delete state.peers[packet.data.from]
                    console.warn(ex)
                  }
                }
              }
              return state;
            case 'wr':
              if (!packet.data.from) {
                console.warn('Unsupported webrtc reset.');
                return state;
              }
              if (state.peers[packet.data.from] && state.peers[packet.data.from].pc) {
                state.peers[packet.data.from].pc.close();
              }
              delete state.peers[packet.data.from]
              return state;
            default:
              console.log('Unhandled ephemeral:', packet)
          }
          break;
        default:
          console.warn("Unsupported Websocket Message", packet)
          break;
      }
      //By default don't update state if we don't know whats going on exactly
      return state;
    }
    default:
      throw new Error(`Bad Action Type: ${action.type}`)
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)
  mitty(GlobalStateContext);
  useEffect(() => {
    //Run Startup Scripts Here
    //TODO: check drift
    setInterval(() => {
      dispatch({ type: "TIMER" })
    }, 1000)
  }, [])
  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
        <RealtimeProvider state={state} dispatch={dispatch} />
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}

export default GlobalContextProvider